import * as React from 'react';
import { ActionButtons } from 'spry-react/ActionButtons/actionButtons';
import { Button } from 'spry-react/Buttons/button';
import { Dialog } from 'spry-react/Dialog/dialog';
export function Render401ErrorDialog(close) {
    return (React.createElement(Dialog, { singleTabText: "Disconnected" },
        React.createElement("p", null, "Your session has expired. Please refresh the page and login."),
        React.createElement(ActionButtons, null,
            React.createElement(Button, { color: "primary", onClick: close }, "Close"))));
}
