import * as React from 'react';
import { Link } from 'spry-react/Link/link';
import './NeedHelpLink.less';
export function NeedHelpLink(props) {
    return (React.createElement(Link, { className: "need-help-link", style: props.large ? { fontSize: '1.2rem' } : undefined, onClick: function () {
            return props.appContext.confirm({
                type: 'warning',
                yesCaption: 'OK',
                message: "For questions or support, please contact the Prosecutor's Office that is providing you discovery.",
            });
        } }, "Need Help?"));
}
