var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { App } from 'spry-react/App/app';
import { FetchWithSerialOperationHandling } from 'spry-react/utils/fetchWithSerialOperationHandling';
import { FetchWithStandardErrorHandling } from 'spry-react/utils/fetchWithStandardErrorHandling';
import { RenderNetworkErrorDialog } from 'spry-react/Errors/networkErrorDialog';
import { Render500ErrorDialog } from 'spry-react/Errors/500ErrorDialog';
import { Render404ErrorDialog } from 'spry-react/Errors/404ErrorDialog';
import { Render403ErrorDialog } from 'spry-react/Errors/403ErrorDialog';
import * as ApiClient from 'spry-react/utils/apiClient';
import { registerCommandApiUrls } from './Utils/getCommandApiUrls';
import { CsrfToken, getCsrfInfo } from './Layout/csrfToken';
import { ErrorLogger } from './ErrorLogging/errorLogger';
import { ErrorLoggerListener } from './ErrorLogging/errorLoggerListener';
import { ErrorBoundary } from './ErrorLogging/errorBoundary';
import { Render401ErrorDialog } from './Errors/401ErrorDialog';
var EdocsApp = /** @class */ (function (_super) {
    __extends(EdocsApp, _super);
    function EdocsApp(props) {
        var _this = _super.call(this, props) || this;
        _this.errorLogger = new ErrorLogger(_this.props.data.layout.errorLoggingUrl, getCsrfInfo);
        return _this;
    }
    EdocsApp.prototype.componentDidMount = function () {
        ErrorLoggerListener.start(this.errorLogger);
    };
    EdocsApp.prototype.componentWillUnmount = function () {
        ErrorLoggerListener.stop();
    };
    EdocsApp.prototype.render = function () {
        var _this = this;
        return (React.createElement(ErrorBoundary, { errorLogger: this.errorLogger },
            React.createElement(CsrfToken, { requestVerificationToken: this.props.data.layout.csrfInfo.requestToken }),
            React.createElement(CommandApiUrlsHiddenInputs, { apiLoadUrl: this.props.data.layout.commandApiUrls.apiLoadUrl, apiExecuteUrl: this.props.data.layout.commandApiUrls.apiExecuteUrl, apiExecuteWithFilesUrl: this.props.data.layout.commandApiUrls.apiExecuteWithFilesUrl }),
            React.createElement(App, { renderChildren: function (openDialog, registerBeforeUnload, flashMessage, confirm, startSerialOperation, setWindowLocationHref) {
                    var openNetworkErrorDialog = function () {
                        openDialog(RenderNetworkErrorDialog);
                    };
                    var open500ErrorDialog = function () {
                        openDialog(Render500ErrorDialog);
                    };
                    var open404ErrorDialog = function () {
                        openDialog(Render404ErrorDialog);
                    };
                    var open401ErrorDialog = function () {
                        openDialog(Render401ErrorDialog);
                    };
                    var open403ErrorDialog = function () {
                        openDialog(Render403ErrorDialog);
                    };
                    var errorHandlers = {
                        handle401Error: open401ErrorDialog,
                        handle403Error: open403ErrorDialog,
                        handle404Error: open404ErrorDialog,
                        handle500Error: open500ErrorDialog,
                        handleNetworkError: openNetworkErrorDialog,
                    };
                    var fetcher = new FetchWithStandardErrorHandling(__assign(__assign({}, errorHandlers), { getCsrfInfo: getCsrfInfo }));
                    var serialFetcher = new FetchWithSerialOperationHandling(startSerialOperation, fetcher);
                    registerCommandApiUrls(_this.props.data.layout.commandApiUrls);
                    var standardApiClient = new ApiClient.ApiClient([
                        new ApiClient.CsrfInfoStep(getCsrfInfo),
                        new ApiClient.StandardErrorHandlingStep(errorHandlers),
                    ]);
                    var serialOperationApiClient = new ApiClient.SerialOperationApiClient(startSerialOperation, standardApiClient);
                    var concurrentOperationApiClient = new ApiClient.ConcurrentRequestApiClient(standardApiClient);
                    var context = {
                        startSerialOperation: startSerialOperation,
                        fetcher: fetcher,
                        serialFetcher: serialFetcher,
                        openDialog: openDialog,
                        confirm: confirm,
                        flashMessage: flashMessage,
                        registerBeforeUnload: registerBeforeUnload,
                        setWindowLocationHref: setWindowLocationHref,
                        standardApiClient: standardApiClient,
                        serialOperationApiClient: serialOperationApiClient,
                        concurrentOperationApiClient: concurrentOperationApiClient,
                    };
                    return _this.props.renderChildren(context);
                } })));
    };
    return EdocsApp;
}(React.Component));
export { EdocsApp };
var CommandApiUrlsHiddenInputs = /** @class */ (function (_super) {
    __extends(CommandApiUrlsHiddenInputs, _super);
    function CommandApiUrlsHiddenInputs() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CommandApiUrlsHiddenInputs.prototype.shouldComponentUpdate = function () {
        return false;
    };
    CommandApiUrlsHiddenInputs.prototype.render = function () {
        return (React.createElement("div", { id: "command-api-urls" },
            React.createElement("input", { name: "ApiLoadUrl", type: "hidden", value: this.props.apiLoadUrl }),
            React.createElement("input", { name: "ApiExecuteUrl", type: "hidden", value: this.props.apiExecuteUrl }),
            React.createElement("input", { name: "ApiExecuteWithFilesUrl", type: "hidden", value: this.props.apiExecuteWithFilesUrl })));
    };
    return CommandApiUrlsHiddenInputs;
}(React.Component));
export { CommandApiUrlsHiddenInputs };
