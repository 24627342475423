import { FeedbackType } from 'spry-react/SerialOperation/serialOperation';
import { getCommandApiUrls } from './getCommandApiUrls';
export function buildCommandRequest(commandName, exeOrLoad, request) {
    var urls = getCommandApiUrls();
    var options = {
        serialOperationOptions: {
            feedbackType: exeOrLoad === 'EXE' ? FeedbackType.FullPageSpinner : FeedbackType.LoadingBanner,
        },
    };
    return {
        method: 'POST',
        url: exeOrLoad === 'EXE' ? "".concat(urls.apiExecuteUrl).concat(commandName) : "".concat(urls.apiLoadUrl).concat(commandName),
        body: { name: commandName, request: request },
        options: options,
    };
}
export function buildWithFilesCommandRequest(commandName, request, files) {
    var urls = getCommandApiUrls();
    var options = {
        serialOperationOptions: {
            feedbackType: FeedbackType.FullPageSpinner,
        },
    };
    var body = new FormData();
    body.append('Request', JSON.stringify(request));
    files.forEach(function (f) { return body.append('File', f); });
    return {
        method: 'POST',
        url: "".concat(urls.apiExecuteWithFilesUrl).concat(commandName),
        body: body,
        options: options,
    };
}
export function buildUploadFilesCommandRequest(commandName, filesForm) {
    var urls = getCommandApiUrls();
    var options = {
        serialOperationOptions: {
            feedbackType: FeedbackType.FullPageSpinner,
        },
    };
    return {
        method: 'POST',
        url: "".concat(urls.apiExecuteWithFilesUrl).concat(commandName),
        body: filesForm,
        options: options,
    };
}
