var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FullPageLoadingBanner } from 'Shared/Layout/fullPageLoadingBanner';
import * as React from 'react';
import { LoginBackground } from './LoginBackground';
import { ErrorDisplayListAutoScrolling } from 'spry-react/Errors/errorDisplayListAutoScrolling';
import ReCAPTCHA from 'react-google-recaptcha';
import { LabeledTextField } from 'spry-react/Text/textField';
import { useRef, useState } from 'react';
import { ErrorList } from 'spry-react/Errors/errorList';
import { onLoad } from 'spry-react/utils/onLoad';
import * as ReactDOM from 'react-dom';
import { EdocsApp } from 'Shared/edocsApp';
import { SignInCommand } from 'Api/GeneratedDTOs/Api/Account/SignInCommand';
import { CheckboxField } from 'spry-react/Checkbox/checkboxField';
import '../../shared/spry/less/lib/spry-icons.less';
import { Link } from 'spry-react/Link/link';
import { DialogRoots } from 'spry-react/DialogPortal/dialogRoots';
import { NeedHelpLink } from './NeedHelpLink';
import { PasswordField } from './PasswordField';
import './signInPage.less';
import { InputIconWrapper } from 'Shared/Fields/inputIconWrapper';
export function SignInPage(props) {
    var _this = this;
    var appContext = props.appContext;
    var _a = useState(new ErrorList([])), errors = _a[0], setErrors = _a[1];
    var _b = useState(''), username = _b[0], setUsername = _b[1];
    var _c = useState(''), password = _c[0], setPassword = _c[1];
    var _d = useState(null), recaptcha = _d[0], setRecaptcha = _d[1];
    var _e = useState(false), requiresCaptcha = _e[0], setRequiresCaptcha = _e[1];
    var captchaRef = useRef(null);
    var _f = useState(false), staySignedIn = _f[0], setStaySignedIn = _f[1];
    var _g = useState(false), showPassword = _g[0], setShowPassword = _g[1];
    document.title = 'Sign In';
    var onSignInClicked = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    return [4 /*yield*/, appContext.serialOperationApiClient.send(SignInCommand.execute({
                            username: username,
                            password: password,
                            recaptchaResponse: recaptcha,
                            staySignedIn: staySignedIn,
                        }))];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        appContext.setWindowLocationHref(props.returnUrl);
                        return [2 /*return*/];
                    }
                    if (response.errors)
                        setErrors(new ErrorList(response.errors));
                    if (response.requiresCaptcha) {
                        if (requiresCaptcha && captchaRef.current)
                            captchaRef.current.reset();
                        setRequiresCaptcha(true);
                    }
                    setPassword('');
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(DialogRoots, null),
        React.createElement(FullPageLoadingBanner, null),
        React.createElement(LoginBackground, { appContext: props.appContext, currentYear: props.currentYear, databaseFriendlyName: props.databaseFriendlyName },
            React.createElement("div", { className: "signin-content" },
                React.createElement("form", { id: "login-form", className: "signin-panel", onSubmit: onSignInClicked, "data-allow-enter": true, style: { paddingTop: 0 } },
                    React.createElement(ErrorDisplayListAutoScrolling, { errors: errors }),
                    React.createElement("div", { className: "field white-label" },
                        React.createElement(InputIconWrapper, { iconClass: "users-icon" },
                            React.createElement(LabeledTextField, { label: "Username", value: username, onChange: setUsername, autoFocus: true }))),
                    React.createElement("div", { className: "field white-label", style: { paddingBottom: '.5rem' } },
                        React.createElement(InputIconWrapper, { iconClass: "lock-icon" },
                            React.createElement(PasswordField, { password: password, setPassword: setPassword, showPassword: showPassword })),
                        React.createElement("div", { className: "no-white-label", style: { display: 'flex' } },
                            React.createElement(ShowPassword, { showPassword: showPassword, setShowPassword: setShowPassword }))),
                    requiresCaptcha && (React.createElement("div", { className: "field l-box-vb" },
                        React.createElement(ReCAPTCHA, { ref: captchaRef, sitekey: props.recaptchaPublicSiteKey, onChange: setRecaptcha }))),
                    React.createElement("button", { type: "submit", className: "pure-button pure-button-primary signin-icon", style: { width: '100%' } }, "Sign In"),
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' }, className: "l-box-vt-1-2x" },
                        React.createElement("div", { className: "field" },
                            React.createElement(StaySignedIn, { staySignedIn: staySignedIn, setStaySignedIn: setStaySignedIn })),
                        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                            React.createElement(Link, { url: props.forgotPasswordUrl, className: "forgot-password-link" }, "Forgot Password?"),
                            React.createElement(Link, { url: props.forgotUserNameUrl, className: "forgot-username-link l-box-vb-1-2x" }, "Forgot Username?"),
                            React.createElement(NeedHelpLink, { appContext: props.appContext, large: true }))))))));
}
function ShowPassword(props) {
    var showPassword = props.showPassword, setShowPassword = props.setShowPassword;
    return (React.createElement("div", { style: { display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' } },
        React.createElement("label", { className: "label l-box-hr-1-2x", htmlFor: "show-password", style: { display: 'inline' } }, "Show Password"),
        React.createElement(CheckboxField, { id: "show-password", value: showPassword, onToggle: function () { return setShowPassword(!showPassword); } })));
}
function StaySignedIn(props) {
    var staySignedIn = props.staySignedIn, setStaySignedIn = props.setStaySignedIn;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'flex', flex: '1 1 auto' } },
            React.createElement(CheckboxField, { id: "stay-signed-in", value: staySignedIn, onToggle: function () { return setStaySignedIn(!staySignedIn); } }),
            React.createElement("label", { className: "label l-box-hl-1-2x", htmlFor: "stay-signed-in", style: { display: 'inline' } }, "Stay Signed In"))));
}
onLoad(function (data) {
    ReactDOM.render(React.createElement(EdocsApp, { data: data, renderChildren: function (context) {
            return React.createElement(SignInPage, __assign({ appContext: context }, data));
        } }), document.getElementById('root'));
});
