/** Sets the singleton RpcCommandApiUrls to be accessed by the code generated command api methods. */
export function registerCommandApiUrls(urls) {
    window.commandApiUrls = urls;
}
/** Gets the singleton RpcCommandApiUrls. */
export function getCommandApiUrls() {
    var commandApiUrls = window.commandApiUrls;
    if (!commandApiUrls)
        throw 'CommandApiUrls have not been registered. Use registerCommandApiUrls() when initializing your app';
    return commandApiUrls;
}
