import * as React from 'react';
import '../../shared/spry/less/spry-login.css';
import './LoginBackground.less';
import { NeedHelpLink } from './NeedHelpLink';
export function LoginBackground(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "product-background" },
            React.createElement("div", { id: "popper-container" }),
            React.createElement("div", { className: "background-gradient-cover gradient" },
                React.createElement("div", { className: "content flex-vertical" },
                    React.createElement("div", { className: "flex-top" },
                        React.createElement("h1", { className: "product-title matrix-logo" },
                            React.createElement("span", { className: "company-name" }, "Matrix"),
                            "Exchange"),
                        React.createElement("h2", { className: "fileshare-and-discovery-subheading" }, "File Share & Discovery"),
                        React.createElement("div", { className: "product-title-subheading l-box-vt-2x l-box-vb-2x" }, "A secure, private network for seamless file and information sharing and collaboration."),
                        React.createElement("div", { id: "login-background-form" }, props.children)),
                    React.createElement("div", { className: "notice-message flex-bottom" },
                        React.createElement("span", { className: "bold-900" }, "NOTICE:"),
                        " For use by Matrix Clients including justice officials, law enforcement agencies, attorneys and authorized designees thereof only. Use by any other person or entity is not authorized. Distribution, dissemination, or other unauthorized use of any material contained herein is strictly prohibited and will be subject to penalties as permitted by law."))),
            React.createElement("footer", { id: "footer" },
                React.createElement("div", { className: "content pure-g" },
                    React.createElement("div", { className: "pure-u-1-8" },
                        React.createElement("span", { className: "matrix-logo" },
                            React.createElement("span", { className: "matrix" }, "Matrix"))),
                    React.createElement("div", { className: "pure-u-1-3 tagline l-box-vt-2x" }, "The Operating System for Law. \u2122"),
                    React.createElement("div", { className: "pure-u-13-24 footer-text" },
                        props.databaseFriendlyName,
                        " | \u00A9 ",
                        props.currentYear,
                        " | Matrix Pointe Software ",
                        React.createElement("br", null),
                        "30400 Detroit Road, Suite 400 | Cleveland, OH 44145 | ",
                        React.createElement(NeedHelpLink, { appContext: props.appContext })))))));
}
