// CHECKSUM:la0yt3tqkoQ2jUgwjtOlcg==
/*
 * This file was autogenerated by the TypeScriptApiGenerator.
 * Generated for Matrix.Edocs.Website.Api.Account.SignInCommand
 * Do not modify this file by hand.
 */
import { buildCommandRequest } from "Shared/Utils/buildCommandRequest";
export var SignInCommand;
(function (SignInCommand) {
    function execute(request) {
        return buildCommandRequest('SignIn', 'EXE', request);
    }
    SignInCommand.execute = execute;
})(SignInCommand || (SignInCommand = {}));
