import * as React from 'react';
export function PasswordField(props) {
    var _a;
    var password = props.password, setPassword = props.setPassword, showPassword = props.showPassword;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { paddingBottom: '.5rem' } },
            React.createElement("label", { className: "label", htmlFor: "password", style: { flex: '1 1 auto' } }, (_a = props.label) !== null && _a !== void 0 ? _a : 'Password'),
            React.createElement("input", { id: "password", type: showPassword ? 'text' : 'password', value: password, onChange: function (e) {
                    setPassword(e.currentTarget.value);
                } }))));
}
