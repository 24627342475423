var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            error: null,
            errorInfo: null,
        };
        return _this;
    }
    ErrorBoundary.prototype.render = function () {
        if (this.state.errorInfo) {
            return (React.createElement(React.Fragment, null,
                React.createElement("h2", null, "An unexpected error has occurred"),
                "The details of the error have been logged. Try refreshing the page and trying the operation again. If the problem persists please contact technical support."));
        }
        return this.props.children;
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        //We're manually logging this error here to get the component stack.
        //The errorLoggerListener will also log react's console.error call, but it doesn't have as much information
        this.props.errorLogger.log({
            message: error.message,
            pageUrl: window.location.href,
            loggedFrom: 'ErrorBoundary.ComponentDidCatch',
            stack: error.stack || null,
            componentStack: errorInfo.componentStack,
            scriptUrl: null,
            userAgent: navigator.userAgent,
            line: null,
        });
        this.setState({ error: error, errorInfo: errorInfo });
    };
    return ErrorBoundary;
}(React.Component));
export { ErrorBoundary };
