export var ErrorLoggerListener;
(function (ErrorLoggerListener) {
    var initialized = false;
    var errLogger;
    var baseConsoleError;
    function start(errorLogger) {
        if (initialized)
            return;
        errLogger = errorLogger;
        //Overwrite console.error so any error logging gets sent to the server even if there is no exception
        //e.g. React logs duplicate key errors but does not throw, we want these errors persisted
        baseConsoleError = console.error;
        console.error = consoleError;
        //Log any uncaught exceptions
        //e.g. exceptions caught outside the react Error Boundary/on old V3 pages
        window.addEventListener('error', logErrorEvent);
        initialized = true;
    }
    ErrorLoggerListener.start = start;
    function stop() {
        if (!initialized)
            return;
        console.error = baseConsoleError;
        window.removeEventListener('error', logErrorEvent);
        errLogger = undefined;
        baseConsoleError = undefined;
        initialized = false;
    }
    ErrorLoggerListener.stop = stop;
    function consoleError(message) {
        baseConsoleError.apply(console, arguments);
        var substitutedMessage = message;
        if (substitutedMessage !== undefined && arguments.length > 1) {
            var otherArgs = Array.prototype.slice.call(arguments, 1);
            var count = 0;
            substitutedMessage = substitutedMessage.replace(/%s/g, function () { return otherArgs[count++]; });
        }
        errLogger.log({
            message: substitutedMessage || null,
            pageUrl: window.location.href,
            loggedFrom: 'console.error',
            stack: null,
            componentStack: null,
            scriptUrl: null,
            userAgent: null,
            line: null,
        });
    }
    function logErrorEvent(e) {
        errLogger.log({
            message: e.message,
            pageUrl: window.location.href,
            loggedFrom: 'window.onerror',
            stack: e.error ? e.error.stack : null,
            componentStack: null,
            scriptUrl: e.filename,
            userAgent: navigator.userAgent,
            line: e.lineno,
        });
    }
})(ErrorLoggerListener || (ErrorLoggerListener = {}));
