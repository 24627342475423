import * as fetch from 'spry-react/utils/fetchExtensions';
var ErrorLogger = /** @class */ (function () {
    function ErrorLogger(errorLoggingUrl, getCsrfInfo) {
        var _this = this;
        this.log = function (error) {
            try {
                fetch.postJson(_this.errorLoggingUrl, error, _this.getCsrfInfo).catch(function () {
                    /* ignore any api errors */
                });
            }
            catch (_a) {
                /* ignore any thrown js errors */
            }
        };
        this.errorLoggingUrl = errorLoggingUrl;
        this.getCsrfInfo = getCsrfInfo;
    }
    return ErrorLogger;
}());
export { ErrorLogger };
